<!-- 空巢老人新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople">选择居民</a-button>
      </div>

      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <!-- 家庭情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">家庭情况</div>
      </div>
      <!-- 家庭情况 -->
      <ax-form ref="form1" :formBuilder="formBuilder2">
        <!-- 子女姓名 -->
        <div slot="childrenName" style="display: flex">
          <a-input v-model="childrenName" placeholder="请选择"></a-input>
          <ax-button @click="chooseChildren" style="margin-left: 2%"
            >选择居民</ax-button
          >
        </div>
        <!-- 紧急联系人 -->
        <div slot="emergencyContact" style="display: flex">
          <a-input v-model="emergencyContact" placeholder="请选择"></a-input>
          <ax-button @click="chooseEmergency" style="margin-left: 2%"
            >选择居民</ax-button
          >
        </div>
      </ax-form>
      <!-- 健康情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">健康情况</div>
      </div>
      <!-- 健康情况 -->
      <ax-form ref="form2" :formBuilder="formBuilder3"> </ax-form>
      <!-- 其他情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">其他情况</div>
      </div>
      <!-- 其他情况 -->
      <ax-form ref="form3" :formBuilder="formBuilder4"> </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId"  :selectedPeopleList="selectedPeopleList"
    ></choose-people>
    <!-- 选择子女弹窗 -->
    <choose-children
      ref="chooseChildren"
      @choose="receiveChirdrenId"
    ></choose-children>
    <!-- 选择紧急联系人弹窗 -->
    <choose-emergency
      ref="chooseEmergency"
      @choose="receiveEmergencyId"
    ></choose-emergency>
  </div>
</template>
<script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入",maxLength:11 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "现住区域",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 24 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "详细地址",
    type: "",
    model: "detailedAddress",
    options: {placeholder:'请选择详细地址'},
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList2 = [
  {
    label: "婚姻状况",
    type: "radio",
    model: "maritalStatus",
    options: {
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "子女数量",
    type: "inputNumber",
    model: "childrenNum",
    options: {
      placeholder:'请输入'
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "子女姓名",
    type: "",
    model: "childrenName",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "子女联系电话",
    type: "",
    model: "childrenPhone",
    options: {placeholder:'请选择子女/输入',maxLength:11},
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "紧急联系人",
    type: "",
    model: "emergencyContact",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "紧急联系人电话",
    type: "",
    model: "emergencyContactPhone",
    options: {placeholder:'请选择紧急联系人/输入',maxLength:11},
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "紧急联系人关系",
    type: "select",
    model: "contactsRelation",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
];
const formList3 = [
  {
    label: "是否自理",
    type: "radio",
    model: "selfCareFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "是否有慢性病",
    type: "radio",
    model: "chronicDiseaseFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "是否残疾",
    type: "radio",
    model: "disabilityFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "最近一次体检日期",
    type: "datePicker",
    model: "physicalExaminationDate",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "体检结果",
    type: "select",
    model: "physicalExaminationResult",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "医保类型",
    type: "select",
    model: "medicalInsuranceType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
];
const formList4 = [
  {
    label: "收入来源",
    type: "select",
    model: "incomeSource",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否建档立卡",
    type: "radio",
    model: "filingFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否享受特助",
    type: "radio",
    model: "assistFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "照料人员",
    type: "select",
    model: "assistType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否纳入社区关怀名单",
    type: "radio",
    model: "careListFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
import ChoosePeople from "./choosePeople.vue";
import ChooseChildren from "./chooseChildren.vue";
import ChooseEmergency from "./chooseEmergency.vue";
export default {
  components: {
    imagePreview,
    ChoosePeople,
    choosePeopleId: "",
    ChooseChildren,
    ChooseEmergency,
  },
  data() {
    return {
      options: [],
      visible: false,
      noChoice: true,
      isEdit: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType:false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 160, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 160 },
        { col: { span: 8 } }
      ),
      formBuilder3: this.$common.initGridFormData(
        formList3,
        { layout: "horizontal", labelWidth: 160 },
        { col: { span: 12 } }
      ),
      formBuilder4: this.$common.initGridFormData(
        formList4,
        { layout: "horizontal", labelWidth: 160 },
        { col: { span: 12 } }
      ),
      childrenName: "", //子女姓名
      emergencyContact: "", //紧急联系人姓名
      baseInfoValue: {}, //基本信息
      familySituation: {}, //家庭情况
      healthCondition: {}, //健康情况
      otherSituations: {}, //其他情况
      selectedPeopleList:[]
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 打开选择子女弹窗
    chooseChildren() {
      this.$refs.chooseChildren.openModal();
    },
    // 打开选择紧急联系人弹窗
    chooseEmergency() {
      this.$refs.chooseEmergency.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    // 接收子组件传过来的子女
    receiveChirdrenId(item) {
      if (item) {
        this.childrenName = item.residentName;
        this.$refs.form1.setFieldsValue({ childrenPhone: item.phone });
      }
    },
    // 接收子组件传过来的经济联系人
    receiveEmergencyId(item) {
      if (item) {
        this.emergencyContact = item.residentName;
        this.$refs.form1.setFieldsValue({ emergencyContactPhone: item.phone });
      }
    },
    // 级联选择器改变的回调
    onChange(value) {
      console.log(value);
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.noChoice = true;
      this.isEdit = true;
      this.saveType = false;
      this.title = record.title;
      this.selectedPeopleList = record.selectedPeopleList
      this.$nextTick(() => {
        this.getDictionsary();
        this.$refs.form1.resetFields();
        this.$refs.form2.resetFields();
        this.$refs.form3.resetFields();
      });
      if (record.id) {
        this.noChoice=false
        this.isEdit=false
        this.businessId = record.residentId;
        this.id = record.id;
        this.getPersonInfo(record.residentId);
        this.getOldsInfo(record.id);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$refs.form1.resetFields();
      this.$refs.form2.resetFields();
      this.$refs.form3.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 空巢老人婚姻状况
      await api
        .dictData({ dicKind: "marital_status" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form1.setFormItemProp("maritalStatus", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 空巢老人联系人关系
      await api
        .dictData({ dicKind: "elderly_contacts_relation" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form1.setFormItemProp("contactsRelation", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 空巢老人收入来源
      await api.dictData({ dicKind: "elderly_income_source" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form3.setFormItemProp("incomeSource", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 空巢老人照料人员
      await api.dictData({ dicKind: "elderly_assist_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form3.setFormItemProp("assistType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 体检结果
      await api.dictData({ dicKind: "physical_examination_result" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("physicalExaminationResult", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 医保类型
      await api.dictData({ dicKind: "medical_insurance_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("medicalInsuranceType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
    },
    // 身份证号改变的回调
    identityCardChange(value, key) {
      switch (key) {
        case "identityCard":
          this.calculateIDCardInfo(value);
          break;
      }
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    async onSubmit() {
      
      if (this.noChoice) {
        this.$message.warning("请先选择人员");
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        this.baseInfoValue.residentId = this.businessId;
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
            this.familySituation = value1;
            this.familySituation.childrenName = this.childrenName
            this.familySituation.emergencyContact = this.emergencyContact
            this.familySituation.contactsRelation = value1.contactsRelation||''
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                console.log(value2);
                this.healthCondition = value2;
                this.healthCondition.physicalExaminationResult = value2.physicalExaminationResult||'';
                this.healthCondition.medicalInsuranceType = value2.medicalInsuranceType||'';
                if (this.$refs.form3) {
                  this.$refs.form3.form.validateFields(async (err, value3) => {
                    if (err) return;
                    console.log(value3);
                    this.otherSituations = value3;
                    this.otherSituations.incomeSource = value3.incomeSource||'';
                    this.otherSituations.assistType = value3.assistType||'';
                    const totalValue = {
                      ...this.baseInfoValue,
                      ...this.familySituation,
                      ...this.healthCondition,
                      ...this.otherSituations,
                    };
                    console.log(totalValue, "375新增数据");
                    this.saveType = true;
                    if (this.id) {
                      totalValue.id = this.id;
                      const res = await api.updateElderly(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("修改成功");
                        this.saveType = false;
                        this.closeModal();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    } else {
                      const res = await api.addElderly(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("新增成功");
                        this.saveType = false;
                        this.closeModal();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
    // 根据id获取详情（详细地址有问题，不明确，需要修改）
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.$refs.form.setFieldsValue(res.data);
      this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`
          : "",
      });
      this.calculateIDCardInfo(res.data.identityCard);
    },
    // 根据id获取空巢老人详情
    async getOldsInfo(id){
      const res = await api.getElderlyById(id);
      this.childrenName = res.data.childrenName
      this.emergencyContact = res.data.emergencyContact
      this.$refs.form1.setFieldsValue(res.data);
      this.$refs.form2.setFieldsValue(res.data);
      this.$refs.form3.setFieldsValue(res.data);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>